const axios = require("axios");
module.exports = {
  POST: function(url, data) {
    console.log('data')
    let params = {}
    for(let i = 0; i < data.length; i++) {

    }
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        method: 'post',
        url: url,
        data: data
      }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })

  }
}
