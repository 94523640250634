<template>
  <div class="delete-account-container">
    <title-header :title="'注销原因'"/>
    <div class="page-container">
      <div class="tips">请告知我们注销原因，以便我们改善平台服务</div>

      <md-check-list
        ref="checkList"
        v-model="checkedArr"
        icon="checked"
        icon-inverse="check"
        :options="reasonArr"
      />
      <md-textarea-item
        v-if="isShowTextBox"
        :autosize="true"
        v-model="reason"
        :max-length="100"
        :max-height="150"
        placeholder="请留下您宝贵的建议，我们将持续优化改进"
      />
      <button v-if="status" :disabled="status === -1" class="btn btn-next" @click="goDeletePage">下一步</button>
      <button v-else class="btn btn-next" @click="cancelDeleteAccount">撤销申请</button>
    </div>
  </div>
</template>

<script>
import TitleHeader from "../../components/TitleHeader";
import {Dialog, Toast} from "mand-mobile/lib-vw/mand-mobile.esm";
import axios from "axios";
import ApiConfig from "../../assets/config/api.config";
import Utils from "../../assets/config/utils";

export default {
  name: "deleteReason",
  components: {
    TitleHeader,
  },
  data() {
    return {
      status: 1,
      isShowTextBox: false,
      reason: '',
      checkedArr: [],
      reasonArr: [
        {value: '停车用户体验差', label: '停车用户体验差'},
        {value: '平台功能不完善', label: '平台功能不完善'},
        {value: '所在城市未开通服务', label: '所在城市未开通服务'},
        {value: '经常遇到故障', label: '经常遇到故障'},
        {value: '多余账号清理', label: '多余账号清理'},
        {value: '其他原因', label: '其他原因'},
      ]
    }
  },
  created() {
    localStorage.removeItem('reason');
    if(JSON.stringify(this.$route.query) !== '{}') {
      if(this.$route.query) {
        localStorage.setItem('query', JSON.stringify(this.$route.query));
      }
      this.getPendingStatus();
    }else {
      localStorage.removeItem('query');
      Dialog.alert({
        title: '提示',
        content: '请先登录',
        confirmText: '确定',
      })
    }
  },
  methods: {
    // 撤销注销账号
    cancelDeleteAccount() {
      let params = {
        user_id: JSON.parse(localStorage.getItem('query')).user_id,
        user_token: JSON.parse(localStorage.getItem('query')).user_token
      }
      axios.post(ApiConfig.cancelDelete, params).then(({data}) => {
        if(data.code === 0) {
          Dialog.alert({
            title: '提示',
            content: '撤销成功',
            confirmText: '确定',
            onConfirm: () => {this.status = 1}
          })
        }else {
          Toast.info(data.msg);
        }
      })
    },
    // 查询账号撤销状态
    getPendingStatus() {
      let params = {
        user_id: JSON.parse(localStorage.getItem('query')).user_id,
        user_token: JSON.parse(localStorage.getItem('query')).user_token
      }
      axios.post(ApiConfig.getDeleteStatus, params).then(({data}) => {
        if(data.code === 0) {
          if(data.content.status === '' || data.content.status === 2 || data.content.status === 3) {
            this.status = 1; // 用户可以提交注销
          }else if(data.content.status === 1) {
            this.status = -1; // 审核成功
          }else if(data.content.status === 0) {
            Toast.info('您的注销账号申请已提交。');
            this.status = 0; // 待审核，可以撤销
          }
        }
      })
    },
    goDeletePage() {
      if(this.checkedArr.length === 0) {
        Dialog.alert({
          title: '提示',
          content: '请您留下宝贵的建议',
          confirmText: '确定',
          onConfirm: () => console.log('[Dialog.alert] confirm clicked'),
        });
      }else {
        localStorage.setItem('reason', this.checkedArr.join(','))
        this.$router.push('/delete-account');
      }

    }
  },
  watch: {
    checkedArr: function(value) {
      this.isShowTextBox = !!value.includes('其他原因');
    }
  }

}
</script>

<style lang="less">
.tips {
  font-size: 0.3rem;
  padding-bottom: 0.25rem;
  border-bottom: 0.1rem solid #E5E5E5;
  margin-bottom: 0.3rem;
}


</style>
